import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

function ScrollView(props: {
    className?: string;
    children?: ReactNode;
})
{
    const contentClasses = twMerge(
        "w-full p-4 absolute top-0 bottom-0 overflow-y-auto block", 
        props.className
    );
    return (
        <div className="flex-1 w-full relative overflow-hidden">
            <div className={contentClasses}>
                { props.children }
            </div>
        </div>
    );
}

export default ScrollView;