import { createContext, useEffect, useState, type ReactNode } from "react";
import Api from "../api/api";
import Spinner from "../components/spinner/spinner";
import modals from "../modals/modals";

interface ContextData {
    user: modals.User | null;

    setUser: (user: modals.User | null) => void;
}

const Context = createContext<ContextData>({} as ContextData);

export const Provider = (props: { 
    children: ReactNode 
}) => 
{
    const [checkingAuth, setCheckingAuth] = useState<boolean>(true);
    const [user, setUser] = useState<modals.User | null>(null);

    const contextData = {
        user,
        setUser
    };

    useEffect(() => 
    {
        (async () => 
        {
            setCheckingAuth(true);
            try
            {
                const response = await Api.get("/checkup");
                if (response.status)
                {
                    const user: modals.User = {
                        id: response.message.id,
                        name: response.message.name,
                        email: response.message.email,
                        newsletter: response.message.newsletter,
                        pswdSet: response.message.pswd_set
                    };
                    setUser(user);   
                }
                else
                {
                    throw new Error(response.error.message);
                }
            }
            catch (err)
            {}
            setCheckingAuth(false);
        })();
    }, []);

    return (
        <Context.Provider value={contextData}>
            {
                checkingAuth ? (
                    <div className="w-screen h-screen flex items-center justify-center">
                        <Spinner size="w-10 h-10"/>
                    </div>
                ) 
                : props.children
            }
        </Context.Provider>
    )
}

export default Provider;
export { Context };