import { useState } from "react";
import Sidebar from "../sidebar/sidebar";
import PageMain from "./pageMain";
import PageContent from "./pageContent";
import PageHeader from "./pageHeader";
import DashPageLoader from "./dashPageLoader";

export default function DashPage(props: {
    title: string,
    loading: boolean,
    actions?: React.ReactNode,
    route?: string,
    children?: React.ReactNode
}) 
{
    const [sidebar, setSidebar] = useState(false);
    const toggleSidebar = () => setSidebar(!sidebar);

	return (
        <>
            <Sidebar route={props.route} active={sidebar} toggleSidebar={toggleSidebar}/>
            <PageMain>
                <PageHeader 
                    title={props.title} 
                    toggleSidebar={toggleSidebar}
                >
                    { props.actions }
                </PageHeader>
                <PageContent>
                    <DashPageLoader loading={props.loading}>
                        {props.children}
                    </DashPageLoader>
                </PageContent>
            </PageMain>
        </>
	);
}