const PageMain = (props: {
    children?: React.ReactNode
}) =>
{
    return (
        <div className="flex">
            <div className="h-full w-0 md:w-64 transition-all"></div>
            <div className="h-full flex-1">
                {props.children}
            </div>
        </div>
    )
}

export default PageMain;