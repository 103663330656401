import { useCallback, useEffect, useState } from 'react';
import Button from '../components/button/button';
import DashPage from '../components/page/dashPage';
import { BrowserSvg, CheckSolidSvg, ClockSvg, CopySvg, MonitorSvg, PersonGroupSvg, PersonSvg, ResetClockSvg, RotateSvg, SaveSvg } from '../components/svgs/svgs';
import { useParams } from 'react-router-dom';
import Api from '../api/api';
import toast from 'react-hot-toast';
import Input from '../components/input/input';
import modals from '../modals/modals';
import Helpers from '../utils/helpers';
import validator from 'validator';
import EmptyState from '../components/empty_state/emptyState';
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../components/modal/modal';

function Device(props: {
    licenseKey: string,
    token: string,
    device: modals.Device,
    onDeviceDeactivate: (deviceToken: string) => void
})
{
    const [loading, setLoading] = useState(false);

    const deactivate = useCallback(async () =>
    {
        setLoading(true);

        try
        {
            const response = await Api.post(
                `/license/${props.licenseKey}/deactivate`, 
                {
                    device_token: props.token
                }
            );

            if (!response.status)
            {
                throw new Error(response.error.message);
            }

            toast.success("Device signed out successfully");
            props.onDeviceDeactivate(props.token);
        }
        catch (err: any)
        {
            toast.error(err.message);
        }

        setLoading(false);
    }, [props]);

    let activationDate = Helpers.getLocalDateTime(props.device.date);

    return (
        <div className="p-4 bg-gray-800 border-2 border-white/10 rounded-lg font-body  transition-colors">
			<div className="pb-2 flex items-center border-b border-white/10 text-sm sm:text-base font-medium text-gray-300">
                <PersonSvg className="w-5 h-5"/>
                <span className="ml-2 flex-1 text-white">{props.device.name}</span>
                <Button 
                    theme="white"
					className="h-9" 
					loading={loading} 
					onClick={deactivate}
				>
                    Deactivate
                </Button>
            </div>
			<div className="mt-4 flex items-center text-sm text-gray-300 font-medium">
                <MonitorSvg className="w-5 h-5"/>
                <span className="ml-2">{ props.device.os } { props.device.os_version }</span>
            </div>
            <div className="mt-2 flex items-center text-sm text-gray-300 font-medium">
                <BrowserSvg className="w-5 h-5"/>
                <span className="ml-2">{ props.device.browser } { props.device.browser_version }</span>
            </div>
			<div className="mt-2 flex items-center text-sm text-gray-300 font-medium">
                <ClockSvg className="w-5 h-5"/>
                <span className="ml-2">{ activationDate }</span>
            </div>
        </div>
    )
}

function Subscription(props: {
    licenseKey: string, 
    details: modals.Subscription,
    onRenewCheckoutSuccess: () => void
})
{
    const [renewing, setRenewing] = useState(false);

    const getRenewUrl = useCallback(async () => 
    {
        setRenewing(true);
        try
        {
            const response = await Api.get(`/license/${props.licenseKey}/renew_url`);
            if (!response.status)
            {
                throw new Error(response.error.message);
            }

            const result : { renew_url: string } = response.message;
            (window as any).Paddle.Checkout.open({
                override: result.renew_url,
                successCallback: () => 
                {
                    console.log('Checkout success!');
                    props.onRenewCheckoutSuccess();
                },
            })
        }
        catch (err: any)
        {
            toast.error(err.message);
        }
        setRenewing(false);
    }, [props]);
    

    return (
        <div className="mt-4">
            {
                props.details.expired ? (
                    <p className="mt-2 text-sm font-body text-red-300">
                        🚫 Subscription expired on {Helpers.getLocalDate(props.details.expired_on)}
                    </p>
                )
                : null 
            }
            {
                props.details.cancelled ? (
                    <p className="mt-2 text-sm font-body text-gray-300">
                        🚫 Subscription cancelled on {Helpers.getLocalDate(props.details.expired_on)}
                    </p>
                )
                : null
            }
            {
                !props.details.expired && !props.details.cancelled ? (
                    <p className="mt-2 text-sm font-body text-gray-300">
                        🟢 Subscription active till {Helpers.getLocalDate(props.details.expiry)}
                    </p>
                )
                : null
            }
            {
                (props.details.renewable && !props.details.cancelled) ? (
                    <div className="mt-4 flex items-center">
                        <Button 
                            theme="green"
                            icon={<ResetClockSvg className="w-4 h-4"/>}
                            loading={renewing}
                            onClick={getRenewUrl}
                        >
                            Renew
                        </Button>
                    </div>
                ) : null
            }
        </div>
    )
}

function License() 
{
	const [loading, setLoading] = useState(false);
    const [licenseKey, setLicenseKey] = useState<modals.LicenseKey>();
    const [savingAssignedEmail, setSavingAssignedEmail] = useState(false);
    const [assignedEmail, setAssignedEmail] = useState<string>("");
    const [resettingActivations, setResettingActivations] = useState(false);
    const [renewModal, setRenewModal] = useState(false);
    const params = useParams<{ key: string }>();
    const key = atob(params.key!);

    const copyLicenseKey = useCallback(() =>
    {
        Helpers.copyText(key);
        toast.success("License key copied");    
    }, 
    [key]);

    const onAssignedEmailChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) =>
    {
        setAssignedEmail(e.target.value);
    }, []);

    const saveAssignedEmail = useCallback(async () =>
    {
        setSavingAssignedEmail(true);

        if (!validator.isEmail(assignedEmail))
        {
            toast.error("Invalid email address");
            return setSavingAssignedEmail(false);
        }

        try
        {
            const response = await Api.post(
                `/license/${key}/set_assigned_email`, 
                {
                    assigned_email: assignedEmail
                }
            );

            if (!response.status)
            {
                throw new Error(response.error.message);
            }

            toast.success("Assigned email saved successfully");
        }
        catch (err: any)
        {
            toast.error(err.message);
        }

        setSavingAssignedEmail(false);
    }, 
    [key, assignedEmail]);

    const resetActivations = useCallback(async () => 
    {
        setResettingActivations(true);

        try
        {
            const response = await Api.post(`/license/${key}/reset`, {});
            if (!response.status)
            {
                throw new Error(response.error.message);
            }
        
            toast.success("Activations reset successfully");
            setLicenseKey((prev) =>
            {
                if (!prev) return prev;
                return { ...prev, devices: {} };
            });
        }
        catch (err: any)
        {
            toast.error(err.message);
        }

        setResettingActivations(false);
    }, 
    [key]);

    const onDeviceDeactivate = useCallback((deviceToken: string) =>
    {
        setLicenseKey((prev) =>
        {
            if (!prev) return;

            const devices = { ...prev.devices };
            delete devices[deviceToken];
            return { ...prev, devices };
        });
    }, []);

    const onRenewCheckoutSuccess = useCallback(() =>
    {
        setRenewModal(true);
    }, []);

    const closeRenewModal = useCallback(() =>
    {
        setRenewModal(false);
    }, []);

    const refresh = useCallback(() =>
    {
        window.location.reload();
    }, []);

	useEffect(() =>
	{
		(async () => 
		{
			setLoading(true);

			try
            {
                const response = await Api.get(`/license/${key}`);
                if (!response.status)
                {
                    throw new Error(response.error.message);
                }

                const licenseKey: modals.LicenseKey = response.message.license_key;
                setLicenseKey(licenseKey)
                setAssignedEmail(licenseKey.assigned_email);
            }
            catch (err: any)
            {
                toast.error(err.message);
            }

			setLoading(false);
		})();
	}, [key]);

	return (
		<DashPage
			title={key}
            route='/license'
			loading={loading}
            actions={
                <Button
                    className="hidden sm:block"
                    icon={<CopySvg className="w-3.5 h-3.5"/>}
                    onClick={copyLicenseKey}
                    isSmall={true}
                >
                    Copy
                </Button>
            }
		>
            <Modal open={renewModal} confetti={true}>
                <ModalHeader onClose={closeRenewModal}>
                    License Key Renewed
                </ModalHeader>
                <ModalBody>
                    <div className="p-4 flex flex-col items-center">
                        <CheckSolidSvg className="mb-6 w-24 h-24 text-green-500 animate-scale-rotate"/>

                        <p className="text-sm font-body text-center text-gray-300">
                            Your license key has been renewed successfully. You would've received an email with the updated details.
                        </p>
                        <p className="mt-4 text-sm font-body text-center text-gray-300">
                            Please refresh the page to see the updated details on the dashboard. If changes are not reflected, please wait for few minutes and try again.
                        </p>

                        <p className="mt-4 text-sm font-body text-center text-gray-300">
                            Contact us at <strong className="text-white">tryhoverify@gmail.com</strong> for support.
                        </p>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button 
                        theme="blue"
                        onClick={refresh}
                    >
                        Done
                    </Button>
                </ModalFooter>
            </Modal>


			<div className="m-auto max-w-2xl">
                <h2 className="text-base font-display font-semibold text-gray-300">
                    Assigned Email Address
                </h2>
                <p className="mt-1 text-sm font-body text-gray-400">
                    This email address will be used for key activation. If left blank, the purchase email address will be used instead.
                </p>
                <Input
                    className="mt-4"
                    value={assignedEmail}
                    placeholder='Email Address'
                    onChange={onAssignedEmailChange}
                />
                <Button
                    className="w-20 mt-4"
                    theme='blue'
                    icon={<SaveSvg className="w-5 h-5"/>}
                    loading={savingAssignedEmail}
                    onClick={saveAssignedEmail}
                >
                    Save
                </Button>

                <div className="mt-12">
                    <h2 className="text-base font-display font-semibold text-gray-300">
                        Subscription
                    </h2>
                    <p className="mt-1 text-sm font-body text-gray-400">
                        We don't do auto-renewals. Your license will expire automaitcally after the subscription period ends. We will notify you before expiration.
                    </p>
                    {
                        licenseKey?.subscription 
                            ? (
                                <Subscription
                                    licenseKey={licenseKey.id}
                                    details={licenseKey.subscription}
                                    onRenewCheckoutSuccess={onRenewCheckoutSuccess}
                                />
                            )
                            : (
                                <p className="mt-4 text-sm font-body text-gray-300">
                                    🎉 You are on lifetime deal plan
                                </p>
                            )
                    }
                </div>

                <div className="mt-12">
                    <div className="flex items-center">
                        <h2 className="flex-1 text-base font-display font-semibold text-gray-300">
                            Activations 
                            <span>({Object.keys(licenseKey?.devices || {}).length}/3)</span>
                        </h2>
                        
                        <Button 
					        isSmall
                            className="h-9" 
                            loading={resettingActivations} 
                            onClick={resetActivations}
                            icon={<RotateSvg className="w-4 h-4"/>}
                        >
                            Reset all
                        </Button>
                    </div>
                    <div className="mt-4 grid grid-cols-1 gap-4">
                        {
                            Object.keys(licenseKey?.devices || {}).length === 0 && (
                                <EmptyState
                                    icon={PersonGroupSvg}
                                    iconStroke={1.5}
                                    title="No activations yet"
                                    description="Activate this key on your devices to start using Hoverify in your browser"
                                />
                            )
                        }
                        {
                            Object.keys(licenseKey?.devices || {}).map((dk) => 
                            (
                                <Device 
                                    key={dk} 
                                    licenseKey={licenseKey!.id}
                                    token={dk}
                                    device={licenseKey?.devices![dk]!}
                                    onDeviceDeactivate={onDeviceDeactivate}
                                />
                            ))
                        }
                    </div>
                </div>
            </div>
		</DashPage>
	);
}

export default License;
