import { ReactNode, useCallback, useContext } from "react";
import { twMerge } from "tailwind-merge";
import { Context } from "../../context/context";
import { Link } from "react-router-dom";
import { DiscordSvg, FacebookSvg, InstallSvg, KeySvg, LogoutSvg, MailSolidSvg, PersonSvg, TwitterSvg } from "../svgs/svgs";
import toast from "react-hot-toast";
import Api from "../../api/api";
import ScrollView from "../scroll_view/scrollView";

const SidebarItem = (props: {
    name: string,
    icon: ReactNode,
    active?: boolean,
    className?: string,
    onClick?: () => void
}) => 
    {
        const classes = twMerge(
            "px-2 py-2 flex items-center text-gray-300 text-base font-body hover:text-white hover:bg-white/5 rounded-md transition-colors cursor-pointer", 
            props.active ? "bg-sky-700 text-white hover:bg-sky-700 hover:text-white" : "", 
            props.className
        );
    
        return (
            <div className={classes} onClick={props.onClick}>
                {props.icon}
                <span className="ml-2">{props.name}</span>
            </div>
        );
    }

export default function Sidebar(props: {
    active: boolean,
    route?: string,
    toggleSidebar: () => void
})
{
    const context = useContext(Context);

    const currentPage = props.route || "licenses";
    const classes = twMerge(
        "w-64 h-screen fixed flex flex-col z-20 top-0 -left-full md:left-0 border-r border-white/10 bg-gray-900 transition-all", 
        props.active ? "left-0 drop-shadow" : ""
    );
    const backdropClasses = twMerge(
        "w-screen h-screen fixed bg-black/20 z-10", 
        props.active ? "block" : "hidden"
    );

    const logout = useCallback(async () => 
    {
        try
        {
            const response = await Api.post("/logout", {});
            if (!response.status)
            {
                throw new Error(response.error.message);
            }

            context.setUser(null);
        }
        catch (err: any)
        {
            toast.error(err.message);
        }
    }, [context]);

    return (
        <>
            <div 
                className={backdropClasses} 
                style={{backdropFilter: "blur(5px)"}}
                onClick={props.toggleSidebar}>    
            </div>

            <div className={classes}>
                <div className="h-14 px-4 flex items-center font-display text-2xl font-medium border-b border-white/10">
                    <div className="w-8 h-8 flex items-center justify-center text-white font-bold font-display text-lg rounded-full bg-blue-600">
                        {context.user?.name.charAt(0).toUpperCase()}
                    </div>
                    <div className="ml-2">
                        <h3 className="text-sm text-white font-medium font-display break-all">
                            {context.user?.name}
                        </h3>
                        <span className="block text-xs text-gray-400 font-body break-all">
                            {context.user?.email}
                        </span>
                    </div>
                </div>
                
                <ScrollView className="px-2 py-4">
                    <div>
                        <Link to="/">
                            <SidebarItem 
                                name="Licenses" 
                                icon={<KeySvg className="w-5 h-5" stroke={2}/>} 
                                active={currentPage === "licenses"}
                            />
                        </Link>

                        <Link className="block mt-2" to="/account">
                            <SidebarItem 
                                name="Account" 
                                icon={<PersonSvg className="w-5 h-5" stroke={2}/>} 
                                active={currentPage === "account"}
                            />
                        </Link>

                        <Link className="block mt-2" to="/install">
                            <SidebarItem 
                                name="Install" 
                                icon={<InstallSvg className="w-5 h-5" stroke={2}/>} 
                                active={currentPage === "install"}
                            />
                        </Link>

                        <SidebarItem 
                            className="mt-2 hover:bg-red-800"
                            name="Log out" 
                            icon={<LogoutSvg className="w-5 h-5" stroke={2}/>}
                            onClick={logout}
                        />
                    </div>
                </ScrollView>

                <ul className="p-4 flex border-t border-white/10 items-center justify-center gap-3">
                    <li>
                        <a
                            className="text-gray-400 hover:text-white transition-colors" 
                            href="https://x.com/hov3rify" 
                            target="_blank"
                            rel="noreferrer"
                        >
                            <TwitterSvg className="w-5 h-5"/>
                        </a>
                    </li>
                    <li>
                        <a
                            className="text-gray-400 hover:text-white transition-colors" 
                            href="https://www.facebook.com/hoverify" 
                            target="_blank"
                             rel="noreferrer"
                        >
                            <FacebookSvg className="w-5 h-5"/>
                        </a>
                    </li>
                    <li>
                        <a
                            className="text-gray-400 hover:text-white transition-colors" 
                            href="mailto:tryhoverify@gmail.com" 
                            target="_blank"
                            rel="noreferrer"
                        >
                            <MailSolidSvg className="w-5 h-5"/>
                        </a>
                    </li>
                    <li>
                        <a
                            className="text-gray-400 hover:text-white transition-colors" 
                            href="https://discord.gg/4AjK5GT69v" 
                            target="_blank"
                            rel="noreferrer"
                        >
                            <DiscordSvg className="w-5 h-5"/>
                        </a>
                    </li>
                </ul>
            </div>
        </>
    )   
}