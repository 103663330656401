import Button from '../components/button/button';
import DashPage from '../components/page/dashPage';

function Install() 
{
	

	return (
		<DashPage
			title="Install"
            route='install'
			loading={false}
		>
			<div className="pt-10 m-auto max-w-2xl grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-6 lg:gap-14">
                <div
                    className="group px-4 py-6 flex flex-col items-center bg-gray-800 shadow-lg rounded-lg hover:rounded-xl" 
                >
                    <div className="relative flex items-center justify-center">
                        <img 
                            src="/images/brave.svg" 
                            alt="brave" 
                            className="w-16 h-16 translate-x-6 group-hover:translate-x-1.5 group-hover:translate-y-4 group-hover:-rotate-45 transition-all duration-300" 
                        />
                        <img 
                            src="/images/chrome.svg" 
                            alt="chrome" 
                            className="w-16 h-16 z-10 group-hover:scale-110 transition-all duration-300" 
                        />
                        <img 
                            src="/images/edge.svg" 
                            alt="edge" 
                            className="w-16 h-16 -translate-x-6 group-hover:-translate-x-0.5 group-hover:translate-y-4 group-hover:rotate-45 transition-all duration-300" 
                        />
                    </div>
                    <h2 className="mt-8 text-white font-display text-2xl font-bold text-center">
                        Chromium
                    </h2>
                    <p className="flex-1 mt-2 text-gray-300 text-center text-sm">
                        Hoverify supports Chrome, Brave, Edge, and Arc. It may work on other Chromium-based browsers, but some features might not function as expected.
                    </p>
                    <a
                        href="https://chromewebstore.google.com/detail/hoverify/bbpokcagpggnekcmamgdieebhpkjmljm"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <Button theme="blue" className="mt-6">
                            Install now
                        </Button>
                    </a>
                </div>

                <div
                    className="group px-4 py-6 flex flex-col items-center bg-gray-800 shadow-lg rounded-lg hover:rounded-xl" 
                >
                    <div className="relative flex items-center justify-center">
                        <img 
                            src="/images/firefox.svg" 
                            alt="chrome" 
                            className="w-16 h-16 z-10 group-hover:scale-110 transition-all duration-300" 
                        />
                    </div>
                    <h2 className="mt-6 text-white font-display text-2xl font-bold text-center">
                        Firefox
                    </h2>
                    <p className="flex-1 mt-2 text-gray-300 text-center text-sm">
                        Hoverify is fully supported on Firefox, offering a seamless experience with all its features.
                    </p>
                    <a
                        href="https://addons.mozilla.org/en-US/firefox/addon/hoverify/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <Button theme="blue" className="mt-6">
                            Install now
                        </Button>
                    </a>
                </div>

			</div>
		</DashPage>
	);
}

export default Install;
