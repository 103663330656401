import { twMerge } from 'tailwind-merge';

function EmptyState(props: {
    class?: string;
    iconClass?: string;
    iconStroke?: number;
    icon: any,
    title: string;
    description: string;
})
{   
    const classes = twMerge(
        "mt-6 mx-auto w-full max-w-sm flex flex-col items-center justify-center font-body text-gray-500", 
        props.class
    );
    const iconClasses = twMerge("w-14 h-14", props.iconClass);
    
    const Icon = props.icon;
    return (
        <div className={classes}>
            <Icon className={iconClasses} stroke={props.iconStroke}/>
            <h4 className="mt-2 text-gray-400 text-base font-semibold">
                { props.title }
            </h4>
            <p className="text-sm font-medium text-center">
                { props.description }
            </p>
        </div>
    );
}

export default EmptyState;