import Spinner from "../spinner/spinner";

function DashPageLoader(props: {
    loading: boolean,
    children?: React.ReactNode
})
{
    return (
        <>
            {
                props.loading ? (
                    <div className="w-full h-40 flex items-center justify-center">
                        <Spinner size="w-8 h-8"/>
                    </div>
                ) 
                : props.children
            }
        </>
    )
}

export default DashPageLoader;