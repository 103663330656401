import { MenuSvg } from "../svgs/svgs";

function PageHeader(props: {
    title: string,
    toggleSidebar: () => void,
    children?: React.ReactNode
})
{
    return (
        <div className="h-14 p-4 flex items-center border-b border-white/10 text-white">
        <div className="mr-2 block md:hidden cursor-pointer" onClick={props.toggleSidebar}>
            <MenuSvg className="w-8 h-8"/>
        </div>
        <h1 className="flex-1 text-xl font-bold font-logo">
            {props.title}
        </h1>
        <div className="ml-4 flex items-center gap-4">
            { props.children }
        </div>
    </div>
    )
}

export default PageHeader;