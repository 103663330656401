import { twMerge } from 'tailwind-merge';
import Confetti from 'react-confetti';
import { XSvg } from '../svgs/svgs';
import IconButton from '../button/icon_button';
import { ReactNode, RefObject, useCallback, useEffect, useRef } from 'react';

function Modal(props: {
    open: boolean;
    confetti?: boolean;
    winClass?: string;
    children?: ReactNode;
})
{
    const parent = useRef<HTMLDivElement>(null);
    const win = useRef<HTMLDivElement>(null);
    
    const winClasses = twMerge("w-full flex flex-col shadow-lg rounded-lg bg-gray-800  scale-50 opacity-0 transition-all duration-200 max-w-lg", props.winClass);

    useEffect(() =>
    {
        if (!parent.current || !win.current) return;

        if (props.open)
        {
            parent.current.classList.remove("hidden");
            parent.current.classList.add("flex");
            
            setTimeout(() => 
            {
                if (!parent.current || !win.current) return;

                parent.current.classList.remove("bg-opacity-0");
                parent.current.classList.add("bg-opacity-100");

                win.current.classList.remove("scale-50");
                win.current.classList.remove("opacity-0");
                win.current.classList.add("scale-100");
                win.current.classList.add("opacity-100");
            }, 10);
        }
        else
        {
            parent.current.classList.remove("bg-opacity-100");
            parent.current.classList.add("bg-opacity-0");

            win.current.classList.remove("scale-100");
            win.current.classList.remove("opacity-100");
            win.current.classList.add("scale-50");
            win.current.classList.add("opacity-0");
            
            setTimeout(() => 
            {
                if (!parent.current) return;

                parent.current.classList.remove("flex");
                parent.current.classList.add("hidden");
            }, 200);
        }
    }, [props.open]);

    return (
        <div ref={parent} className="w-full h-full fixed top-0 left-0 p-4 z-20 hidden items-center justify-center bg-black/30 bg-opacity-0 transition-all duration-200">
            {
                props.confetti && props.open
                ? (
                    <Confetti
                        width={window.innerWidth}
                        height={window.innerHeight}
                        recycle={false}
                    />
                ) : null
            }
            <div ref={win} className={winClasses}>
                {props.children}
            </div>
        </div>
    );
}

function ModalHeader(props: {
    children?: any;
    onClose?: () => void;
})
{
    const close = useCallback(() => 
    {
        if (props.onClose) props.onClose();
    }, [props])

    return (
        <div className="h-12 pl-4 pr-2 pt-2 pb-2 flex items-center border-b border-white/10">
            <h1 className="flex-1 text-lg font-semibold text-white font-body">{props.children}</h1>
            <IconButton onClick={close}>
                <XSvg className="w-6 h-6"/>
            </IconButton>
        </div>
    );
}

function ModalBody(props: {
    containerRef?: RefObject<HTMLDivElement>;
    class?: string;
    children?: ReactNode;
})
{
    const classes = twMerge("p-4 flex flex-col flex-1", props.class);
    return (
        <div className={classes} ref={props.containerRef}>
            {props.children}
        </div>
    );
}

function ModalFooter(props: {
    class?: string;
    children?: ReactNode;
})
{
    const classes = twMerge("h-16 px-4 py-2 flex items-center justify-end border-t border-white/10", props.class);
    return (
        <div className={classes}>
            {props.children}
        </div>
    );
}

export default Modal;
export { 
    ModalHeader,
    ModalBody,
    ModalFooter
};