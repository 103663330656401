import { ReactNode } from "react"
import { Link } from "react-router-dom"
import { twMerge } from "tailwind-merge"

function A(props: {
    href: string;
    className?: string;
    children?: ReactNode;
}) 
{
    const classes = twMerge(
        "font-body hover:text-blue-600 transition-colors",
        props.className
    )

    return (
        <Link to={props.href} className={classes}>
            { props.children }
        </Link>
    )
}

export default A;