const PageContent = (props: {
    children?: React.ReactNode
}) =>
{
    return (
        <div className="p-4 flex-col">
            {props.children}
        </div>
    )
}

export default PageContent;