import React, { useCallback, useState } from 'react';
import validator from 'validator';
import Box from '../components/box/box';
import Input from '../components/input/input';
import Button from '../components/button/button';
import Page from '../components/page/page';
import toast from 'react-hot-toast';
import Api from '../api/api';

function ForgotPassword() 
{
	const [email, setEmail] = useState<string>('');
	const [sending, setSending] = useState(false);

	const onEmailChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(e.target.value);
	}, []);

	const sendMail = useCallback(async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => 
	{
		e.preventDefault();

		if (!validator.isEmail(email))
		{
			toast.error("Invalid email address.");
			return;
		}

		setSending(true);
		try
		{
			const response = await Api.post("/forgot_password", { email });
			if (response.status)
			{
				toast.success("Email sent. Please check your inbox.");
			}	
			else
			{
				throw new Error(response.error.message);
			}
		}
		catch (err: any)
		{
			toast.error(err.message);
		}

		setSending(false);
	}, 
	[email]);

	return (
		<Page isAuthRequired={false}>
			<div className="w-screen h-screen flex items-center justify-center">
				<div className="w-full max-w-sm">
					<div className="w-full flex items-center justify-center">
						<img className="w-6 h-6" src="/images/logo.png" alt='logo' />
						<h1 className="ml-2 font-logo font-bold text-2xl text-white">
							Hoverify
						</h1>
					</div>
					<Box className="mt-4">
						<h2 className="font-body text-white text-xl font-medium">
							Forgot password
						</h2>
                        <p className="mt-1 font-body text-gray-300 text-sm">
                            Enter your email address to receive a password reset link.
                        </p>
						<form className="mt-6 flex flex-col gap-4">
							<Input
								value={email}
								label="Email address"
								placeholder="Your email address"
								onChange={onEmailChange}
							/>

							<Button 
                                className="mt-4"
								theme='blue' 
								onClick={sendMail} 
								loading={sending}
							>
								Send reset link
							</Button>
						</form>
					</Box>
				</div>
			</div>
		</Page>
	);
}

export default ForgotPassword;