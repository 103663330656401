import axios from "axios";

interface Response {
    status: boolean;
    message: any;
    error: {
        message: string;
    }
}

// const baseUrl = 'http://localhost:8000/dashboard';
const baseUrl = 'https://api.tryhoverify.com/dashboard';

function getURL(path: string): string {
    return `${baseUrl}${path.startsWith('/') ? path : `/${path}`}`;
}

async function get(path: string): Promise<Response> 
{
    try
    {
        const { data } = await axios.get(
            getURL(path),
            { 
                withCredentials: true 
            }
        );
        const response: Response = {
            status: data.status === ":)",
            message: data.message,
            error: data.error
        }
        return response;
    }
    catch (err)
    {
        throw err;
    }
}

async function post(path: string, body: any): Promise<Response>
{
    try
    {
        const { data } = await axios.post(
            getURL(path), 
            body,
            {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
        );
        const response: Response = {
            status: data.status === ":)",
            message: data.message,
            error: data.error
        }
        return response;
    }
    catch (err)
    {
        throw err;
    }
}

const Api = {
    get,
    post
};

export default Api;