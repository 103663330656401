import React, { useCallback, useContext, useState } from 'react';
import Box from '../components/box/box';
import Input from '../components/input/input';
import Button from '../components/button/button';
import Page from '../components/page/page';
import toast from 'react-hot-toast';
import Api from '../api/api';
import { Context } from '../context/context';
import { useNavigate, useParams } from 'react-router-dom';

function ResetPassword() 
{
	const navigate = useNavigate();
	const context = useContext(Context);
	const [password, setPassword] = useState<string>('');
	const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [reseting, setReseting] = useState(false);
    const params = useParams<{ token: string }>();

	const onPasswordChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => 
    {
		setPassword(e.target.value);
	}, []);

    const onConfirmPasswordChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) =>
    {
        setConfirmPassword(e.target.value);
    }, []);

	const reset = useCallback(async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => 
	{
		e.preventDefault();

        if (password.length < 4)
        {
            toast.error("Password cannot be less than 4 characters.");
            return;
        }

        if (password.length > 50)
        {
            toast.error("Password cannot be more than 50 characters.");
            return;
        }

        if (password !== confirmPassword)
        {
            toast.error("Passwords do not match.");
            return;
        }

		setReseting(true);

		try
		{
            console.log(params.token)
			const response = await Api.post(
                "/reset_password", 
                { 
                    token: params.token,
                    password, 
                    confirm_password: confirmPassword 
                }
            );
			if (response.status)
			{
                toast.success("Password reset successfully.");
				navigate("/login", { replace: true });
			}	
			else
			{
				throw new Error(response.error.message);
			}
		}
		catch (err: any)
		{
			toast.error(err.message);
		}

		setReseting(false);
	}, 
	[password, navigate, confirmPassword, params.token]);

	return (
		<Page isAuthRequired={false}>
			<div className="w-screen h-screen flex items-center justify-center">
				<div className="w-full max-w-sm">
                    <div className="w-full flex items-center justify-center">
						<img className="w-6 h-6" src="/images/logo.png" alt='logo' />
						<h1 className="ml-2 font-logo font-bold text-2xl text-white">
							Hoverify
						</h1>
					</div>
					<Box className="mt-4">
						<h2 className="font-body text-white text-xl">
							Enter your new password
						</h2>
						<form className="mt-6 flex flex-col gap-4">
                            <Input
								value={context.user?.email || ""}
								label="Email address"
								placeholder="Your email address"
                                className='hidden'
                                readOnly={true}
							/>
                            <Input
								type="password"
								value={password}
								label="Password"
								placeholder="••••••••"
								onChange={onPasswordChange}
							/>
							<Input
								type="password"
								value={confirmPassword}
								label="Confirm password"
								placeholder="••••••••"
								onChange={onConfirmPasswordChange}
							/>

							<Button 
                                className="mt-4"
								theme='blue' 
								onClick={reset} 
								loading={reseting}
							>
								Reset password
							</Button>
						</form>
					</Box>
				</div>
			</div>
		</Page>
	);
}

export default ResetPassword;