import Page from '../components/page/page';
import { Outlet } from 'react-router-dom';

function App() 
{
	return (
		<Page isAuthRequired>
			<Outlet />
		</Page>
	);
}

export default App;
