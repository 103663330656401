import type { MouseEventHandler, ReactNode } from "react";
import { twMerge } from "tailwind-merge";
import Spinner from "../spinner/spinner";

function Button(props: {
    id?: string;
    icon?: ReactNode;
    theme?: string;
    className?: string;
    children?: ReactNode;
    loading?: boolean;
    isSmall?: boolean;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    onMouseEnter?: MouseEventHandler<HTMLButtonElement>;
    onMouseLeave?: MouseEventHandler<HTMLButtonElement>;
})
{
    let theme = "bg-slate-600 hover:bg-slate-700 text-gray-200 hover:text-white";
    switch (props.theme)
    {
        case "blue": theme = "bg-blue-700 hover:bg-blue-800"; break;
        case "green": theme = "bg-green-700 hover:bg-green-800"; break;
        case "red": theme = "bg-red-800 hover:bg-red-900"; break;
    }

    const classes = twMerge(
        "relative px-3 h-10 flex items-center justify-center text-white rounded-md font-body text-sm font-medium transition-colors",
        theme,
        props.isSmall ? "h-8 text-xs" : "",
        props.className,
    );

    return (
        <button
            id={props.id}
            className={classes}
            onClick={props.onClick}
            onMouseEnter={props.onMouseEnter}
            onMouseLeave={props.onMouseLeave}
        >
            { 
                props.loading 
                ? (
                    <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                        <Spinner size="w-5 h-5"/>
                    </div>
                ) : null 
            }

            <div className={"flex items-center " + (props.loading ? "invisible" : "")}>
                {
                    props.icon 
                }
                <span className={ props.icon ? "ml-1.5" : ""}>{ props.children }</span>
            </div>
        </button>
    );
}

export default Button;