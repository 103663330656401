import { useCallback } from 'react';
import { twMerge } from 'tailwind-merge';
import { CheckSvg } from '../svgs/svgs';

export interface ICheckboxProps {
    className?: string;
    label: string;
    checked: boolean;
    description?: string;
    disabled?: boolean;
    onChange?: (status: boolean) => void;
}

function Checkbox(props: ICheckboxProps)
{
    const onChange = useCallback(() => 
    {
        if (props.disabled) return;
        if (props.onChange) props.onChange(!props.checked);
    }, 
    [props]);

    const classes = twMerge(
        "flex transition-colors text-white font-body text-sm select-none", 
        props.className,
        props.disabled ? "opacity-80 cursor-not-allowed" : "cursor-pointer hover:text-white"
    );

    const checkBoxClasses = "w-5 h-5 flex items-center justify-center rounded border text-white transition-colors "
        + (props.checked ? "bg-blue-600 border-blue-600" : "bg-transparent border-white/20");

    const checkClasses = "w-3.5 h-3.5 transition-transform " 
        + (props.checked ? "scale-100" : "scale-0");
    
    return (
        <div className={classes} onClick={onChange}>
            <div className={checkBoxClasses}>
                <CheckSvg className={checkClasses} stroke={3}/>
            </div>
            <div className="ml-2 flex flex-col flex-1">
                <label className="font-medium">{ props.label }</label>
                { 
                    props.description ? (
                        <p className="mt-0.5 text-gray-400 font-normal">{ props.description }</p>
                    ) : null
                }
            </div>
        </div>
    );
}

export default Checkbox;