import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import './index.scss';
import App from './routes/app';
import Login from './routes/login';
import reportWebVitals from './reportWebVitals';
import Provider from './context/context';
import Licenses from './routes/licenses';
import License from './routes/license';
import Install from './routes/install';
import Account from './routes/account';
import SetPassword from './routes/setPassword';
import ForgotPassword from './routes/forgotPassword';
import ResetPassword from './routes/ResetPassword';

const router = createBrowserRouter([
	{
		path: '/',
		element: <App />,
		children: [
			{
				path: '/set-password',
				element: <SetPassword />,
			},
			{
				path: '/',
				element: <Licenses />
			},
			{
				path: '/license/:key',
				element: <License />
			},
			{
				path: '/account',
				element: <Account />
			},
			{
				path: '/install',
				element: <Install />,
			}
		]
	},
	{
		path: '/login',
		element: <Login />
	},
	{
		path: '/forgot-password',
		element: <ForgotPassword />
	},
	{
		path: '/reset-password/:token',
		element: <ResetPassword />
	}
]);

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

root.render(
	<Provider>
		<RouterProvider router={router}/>
		<Toaster
			position="top-center"
			reverseOrder={true}
		/>
	</Provider>
);

reportWebVitals();
