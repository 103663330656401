import Button from '../components/button/button';
import DashPage from '../components/page/dashPage';
import Input from '../components/input/input';
import { useCallback, useContext, useState } from 'react';
import { SaveSvg } from '../components/svgs/svgs';
import Checkbox from '../components/checkbox/checkbox';
import { Context } from '../context/context';
import Api from '../api/api';
import toast from 'react-hot-toast';

function Account() 
{
    const context = useContext(Context);

    const [name, setName] = useState<string>(context.user?.name || '');
    const [newsletter, setNewsletter] = useState<boolean>(context.user?.newsletter || false);
    const [savingAccountSettings, setSavingAccountSettings] = useState<boolean>(false);

    const [oldPassword, setOldPassword] = useState<string>('');
    const [newPassword, setNewPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [changingPassword, setChangingPassword] = useState<boolean>(false);
    
    const onNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => 
    {
		setName(e.target.value);
	}, []);

    const onNewsletterChange = useCallback((status: boolean) => 
    {
        setNewsletter(status);
    }, []);

    const onOldPasswordChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => 
    {
        setOldPassword(e.target.value);
    }, []);

    const onNewPasswordChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => 
    {
        setNewPassword(e.target.value);
    }, []);

    const onConfirmPasswordChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => 
    {
        setConfirmPassword(e.target.value);
    }, []);

    const saveAccountSettings = useCallback(async () => 
    {
        if (!name)
        {
            toast.error("Name cannot be empty.");
            return;
        }

        if (name.length > 40)
        {
            toast.error("Name cannot be more than 40 characters.");
            return;   
        }

        setSavingAccountSettings(true);
        try
        {
            const response = await Api.post("/account/change_settings", {
                name,
                newsletter
            });

            if (!response.status)
            {
                throw new Error(response.error.message);
            }

            toast.success("Account settings saved successfully.");
        }
        catch (err: any)
        {
            toast.error(err.message);    
        }

        setSavingAccountSettings(false);
    }, 
    [name, newsletter]);

    const changePassword = useCallback(async () => 
    {
        if (!oldPassword)
        {
            toast.error("Old password cannot be empty.");
            return;
        }

        if (newPassword.length < 4)
        {
            toast.error("Password cannot be less than 4 characters.");
            return;
        }

        if (newPassword.length > 50)
        {
            toast.error("Password cannot be more than 50 characters.");
            return;
        }

        if (newPassword !== confirmPassword)
        {
            toast.error("Passwords do not match.");
            return;
        }

        setChangingPassword(true);
        try
        {
            const response = await Api.post("/account/change_password", {
                old_password: oldPassword,
                password: newPassword,
                confirm_password: confirmPassword,
            });

            if (!response.status)
            {
                throw new Error(response.error.message);
            }

            toast.success("Password changed successfully.");
        }
        catch (err: any)
        {
            toast.error(err.message);    
        }

        setChangingPassword(false);
    }, [oldPassword, newPassword, confirmPassword]);

	return (
		<DashPage
			title="Account"
            route='account'
			loading={false}
		>
            <div className="m-auto max-w-lg">
                <h2 className="text-base font-display font-semibold text-gray-300">
                    Account Settings
                </h2>

                <Input
                    className='mt-4'
                    value={name}
                    label="Name"
                    placeholder="Himanshu Mishra"
                    onChange={onNameChange}
                />
                
                <Checkbox
                    className='mt-4'
                    label="Receive emails about updates, deals, and new products."
                    description="We don't believe in spamming and only rarely send promotional emails."
                    checked={newsletter}
                    onChange={onNewsletterChange}
                />

                <Button 
                    className='mt-6'
                    theme='blue' 
                    onClick={saveAccountSettings} 
                    loading={savingAccountSettings}
                    icon={<SaveSvg className='w-5 h-5'/>}
                >
                    Save
                </Button>

                <h2 className="mt-12 text-base font-display font-semibold text-gray-300">
                    Change Password
                </h2>

                <form>
                    <Input
                        value={context.user?.email || ""}
                        label="Email address"
                        placeholder="Your email address"
                        className='hidden'
                        readOnly={true}
                    />

                    <Input
                        className='mt-4'
                        type='password'
                        value={oldPassword}
                        label="Old Password"
                        placeholder="••••••••"
                        onChange={onOldPasswordChange}
                    />

                    <Input
                        className='mt-4'
                        type='password'
                        value={newPassword}
                        label="New Password"
                        placeholder="••••••••••••••••"
                        onChange={onNewPasswordChange}
                    />

                    <Input
                        className='mt-4'
                        type='password'
                        value={confirmPassword}
                        label="Confirm Password"
                        placeholder="••••••••••••••••"
                        onChange={onConfirmPasswordChange}
                    />

                    <Button 
                        className='mt-6'
                        theme='blue' 
                        onClick={changePassword} 
                        loading={changingPassword}
                        icon={<SaveSvg className='w-5 h-5'/>}
                    >
                        Change
                    </Button>
                </form>
            </div>
		</DashPage>
	);
}

export default Account;
