import { ReactNode, useContext, useEffect } from "react";
import { Context } from "../../context/context";
import { useNavigate } from "react-router-dom";

function Page(props: {
    isAuthRequired: boolean,
    children?: ReactNode
})
{
    const navigate = useNavigate();
    const { user } = useContext(Context);

    useEffect(() => 
    {
        if (props.isAuthRequired && !user)
        {
            navigate('/login', { replace: true });
        }
        else if (props.isAuthRequired && user && !user.pswdSet) 
        {
            navigate('/set-password', { replace: true });
        }
    }, 
    [user, props.isAuthRequired, navigate])

    return <>
        {
            props.isAuthRequired && !user ? null : props.children
        }
    </>;
}

export default Page;