
function getLocalDate(gmt: string): string
{
    const formatter = new Intl.DateTimeFormat("en", {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });

    return formatter.format(new Date(gmt));
}

function getLocalDateTime(gmt: string): string
{
    const formatter = new Intl.DateTimeFormat("en", {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
    });

    return formatter.format(new Date(gmt));
}

function copyText(text: string)
{
    const fallback = () => 
    {
        const textarea = document.createElement('textarea');
        document.body.appendChild(textarea);
        textarea.innerHTML = text;
        textarea.select();
        document.execCommand('copy');
        textarea.remove();
    }

    if (navigator.clipboard)
    {
        (async () => 
        {
            try
            {
                await navigator.clipboard.writeText(text);
                return true;
            }
            catch (err) { fallback(); }
        })();
    }
    else fallback();
}


const Helpers = {
    getLocalDate,
    getLocalDateTime,
    copyText
};

export default Helpers;