import type { FormEventHandler, KeyboardEventHandler } from 'react';
import { twMerge } from 'tailwind-merge';

function Input(props: {
    type?: string;
    className?: string;
    label?: string;
    labelActions?: any;
    value: string;
    placeholder?: string;
    maxLength?: number;
    disabled?: boolean;
    autoFocus?: boolean;
    readOnly?: boolean;

    onChange?: FormEventHandler<HTMLInputElement>;
    onBlur?: FormEventHandler<HTMLInputElement>;
    onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
})
{
    const type = props.type ? props.type : "text";
    const isLabel = props.label || props.labelActions;
    const classes = twMerge(isLabel ? "h-16" : "h-10", `flex flex-col`, props.className);

    return (
        <div className={classes}>
            {
                isLabel ? (
                    <div className="mb-1 flex items-center text-sm text-gray-400 font-body font-medium">
                        <label className="flex-1">
                            { props.label }
                        </label>
                        {
                            props.labelActions
                        }
                    </div>
                ) : null
            }
    
            <input
                type={type} 
                className="w-full flex-1 min-w-0 rounded bg-gray-700 px-2 text-base text-white font-body outline-none disabled:text-gray-300" 
                value={props.value}
                placeholder={props.placeholder} 
                maxLength={props.maxLength}
                onChange={props.onChange}
                onBlur={props.onBlur}
                onKeyDown={props.onKeyDown}
                disabled={props.disabled}
                spellCheck={false}
                autoComplete={type === "password" ? "current-password" : "on"}
                readOnly={props.readOnly}
            />
        </div>
    );
}

export default Input;