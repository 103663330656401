import { useCallback, useEffect, useState } from 'react';
import DashPage from '../components/page/dashPage';
import { KeySvg, MailSvg, PersonSvg, SubscriptionSvg } from '../components/svgs/svgs';
import { Link } from 'react-router-dom';
import Api from '../api/api';
import toast from 'react-hot-toast';
import modals from '../modals/modals';
import Helpers from '../utils/helpers';

function LicenseKey(props: {
	licenseKey: modals.LicenseKey,
	activationsReset: (key: string) => void
})
{
	let expiry = "", expiredOn = "";
	if (props.licenseKey.subscription)
	{
		expiry = Helpers.getLocalDate(props.licenseKey.subscription.expiry);

		if (props.licenseKey.subscription.expired)
		{
			expiredOn = Helpers.getLocalDate(props.licenseKey.subscription.expired_on);
		}
	}

	return (
		<Link to={`/license/${btoa(props.licenseKey.id)}`} className="p-4 bg-gray-800 border-2 border-white/10 rounded-lg font-body hover:bg-gray-800 hover:border-gray-600 transition-colors">
			<div className="pb-2 flex items-center border-b border-white/10 text-sm sm:text-base font-medium text-gray-300">
                <KeySvg className="w-5 h-5"/>
                <span className="ml-2 flex-1 text-white">{props.licenseKey.id}</span>
            </div>
			<div className="mt-4 flex items-center text-sm text-gray-300 font-medium">
                <MailSvg className="w-5 h-5"/>
                <span className="ml-2">{ props.licenseKey.assigned_email || "-" }</span>
            </div>
            <div className="mt-2 flex items-center text-sm text-gray-300 font-medium">
                <PersonSvg className="w-5 h-5"/>
                <span className="ml-2">Activations {props.licenseKey.activations}/3</span>
            </div>
			<div className="mt-2 flex items-center text-sm text-gray-300 font-medium">
                <SubscriptionSvg className="w-5 h-4"/>
				{
					props.licenseKey.subscription 
						? props.licenseKey.subscription.expired 
							? (
								<span className="ml-2 text-red-300">
									Expired on { expiredOn }
								</span>
							) : (
								<span className="ml-2">Expires on { expiry }</span>
							)
						: (
							<span className="ml-2">You are on lifetime deal plan 🎉</span>
						)
				}
            </div>
        </Link>
	)
}

function Licenses() 
{
	const [loading, setLoading] = useState(false);
	const [licenseKeys, setLicenseKeys] = useState<modals.LicenseKey[]>([]);
	const [activationCount, setActivationCount] = useState(0);

	const activationsReset = useCallback((key: string) =>
	{
		let activationsToRemove = 0;
		setLicenseKeys((prev) => prev.map((licenseKey) => 
		{
			if (licenseKey.id === key)
			{
				activationsToRemove = licenseKey.activations;
				licenseKey.activations = 0;
			}

			return licenseKey;
		}));

		setActivationCount((prev) => prev - activationsToRemove);
	}, []);

	useEffect(() =>
	{
		(async () => 
		{
			setLoading(true);

			try
			{
				const response = await Api.get("/licenses");
				if (!response.status)
				{
					throw new Error(response.error.message);
				}

				setLicenseKeys(response.message.license_keys);
				const actCount = response.message.license_keys.reduce(
					(acc: number, curr: modals.LicenseKey) => acc + curr.activations, 
					0
				);
				setActivationCount(actCount);
			}
			catch (err: any)
			{
				toast.error(err.message)
			}

			setLoading(false);
		})();
	}, []);

	return (
		<DashPage
			title="Licenses"
			loading={loading}
		>
			<div className="m-auto max-w-2xl">
				<div className="grid grid-cols-3 gap-2 sm:gap-6">
					<div className="py-4 px-2 sm:px-4 flex flex-col items-center bg-gradient-to-br from-gray-700 to-slate-800 rounded-xl shadow-lg font-body">
						<span className="flex-1 font-medium text-xs text-gray-300 text-center">
							LICENSE KEYS
						</span>
						<span className="mt-2 text-5xl font-bold text-white">
							{
								licenseKeys.length < 10 ? `0${licenseKeys.length}` : licenseKeys.length
							}
						</span>
					</div>
					<div className="py-4 px-2 sm:px-4 flex flex-col items-center bg-gradient-to-br from-gray-700 to-slate-800 rounded-xl shadow-lg font-body">
						<span className="flex-1 font-medium text-xs text-gray-300 text-center">
							ACTIVATIONS
						</span>
						<span className="mt-2 text-5xl font-bold text-white">
							{
								licenseKeys.length * 3 < 10 ? `0${licenseKeys.length * 3}` : licenseKeys.length * 3
							}
						</span>
					</div>
					<div className="py-4 px-2 sm:px-4 flex flex-col items-center bg-gradient-to-br from-gray-700 to-slate-800 rounded-xl shadow-lg font-body">
						<span className="flex-1 font-medium text-xs text-gray-300 text-center">
							USED ACTIVATIONS
						</span>
						<span className="mt-2 text-5xl font-bold text-white">
							{
								activationCount < 10 ? `0${activationCount}` : activationCount
							}
						</span>
					</div>
				</div>

				<div className="mt-12">
					<h2 className="text-base font-display font-semibold text-gray-300">
						License Keys
					</h2>

					<div className="mt-4 grid grid-cols-1 gap-4">
						{
							licenseKeys.map((licenseKey) => 
							(
								<LicenseKey 
									key={licenseKey.id} 
									licenseKey={licenseKey}
									activationsReset={activationsReset}
								/>
							))
						}
					</div>
				</div>

			</div>
		</DashPage>
	);
}

export default Licenses;
