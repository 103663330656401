import React, { useCallback, useContext, useState } from 'react';
import Box from '../components/box/box';
import Input from '../components/input/input';
import Button from '../components/button/button';
import Page from '../components/page/page';
import toast from 'react-hot-toast';
import Api from '../api/api';
import { Context } from '../context/context';
import modals from '../modals/modals';
import { useNavigate } from 'react-router-dom';

function SetPassword() 
{
	const navigate = useNavigate();
	const context = useContext(Context);
	const [password, setPassword] = useState<string>('');
	const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [saving, setSaving] = useState(false);

	const onPasswordChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => 
    {
		setPassword(e.target.value);
	}, []);

    const onConfirmPasswordChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) =>
    {
        setConfirmPassword(e.target.value);
    }, []);

	const save = useCallback(async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => 
	{
		e.preventDefault();

        if (password.length < 4)
        {
            toast.error("Password cannot be less than 4 characters.");
            return;
        }

        if (password.length > 50)
        {
            toast.error("Password cannot be more than 50 characters.");
            return;
        }

        if (password !== confirmPassword)
        {
            toast.error("Passwords do not match.");
            return;
        }

		setSaving(true);

		try
		{
			const response = await Api.post(
                "/set_password", 
                { 
                    password, 
                    confirm_password: confirmPassword 
                }
            );
			if (response.status)
			{
				const user: modals.User = {...context.user!, pswdSet: true};
				context.setUser(user);
				navigate("/", { replace: true });
			}	
			else
			{
				throw new Error(response.error.message);
			}
		}
		catch (err: any)
		{
			toast.error(err.message);
		}

		setSaving(false);
	}, 
	[password, context, navigate, confirmPassword]);

	return (
		<Page isAuthRequired={true}>
			<div className="w-screen h-screen flex items-center justify-center">
				<div className="w-full max-w-md">
					<Box className="mt-4">
						<h2 className="font-body text-white text-xl font-medium">
							Set new password
						</h2>
                        <p className="mt-1 font-body text-gray-300 text-sm">
                            You are currently using a temporary password. Please set a new password to continue.
                        </p>
						<form className="mt-6 flex flex-col gap-4">
                            <Input
								value={context.user?.email || ""}
								label="Email address"
								placeholder="Your email address"
                                className='hidden'
                                readOnly={true}
							/>
                            <Input
								type="password"
								value={password}
								label="Password"
								placeholder="••••••••"
								onChange={onPasswordChange}
							/>
							<Input
								type="password"
								value={confirmPassword}
								label="Confirm password"
								placeholder="••••••••"
								onChange={onConfirmPasswordChange}
							/>

							<Button 
                                className="mt-4"
								theme='blue' 
								onClick={save} 
								loading={saving}
							>
								Save password
							</Button>
						</form>
					</Box>
				</div>
			</div>
		</Page>
	);
}

export default SetPassword;