import { MouseEventHandler, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

const IconButton = (props: {
    class?: string;
    onClick?: MouseEventHandler<HTMLDivElement>;
    children?: ReactNode;
}) => 
{

    const classes = twMerge("w-8 h-8 flex items-center justify-center text-gray-400 rounded-full cursor-pointer hover:text-white transition-colors select-none hover:bg-white/10", props.class);
    return (
        <div className={classes} onClick={props.onClick}>
            { props.children }
        </div>
    );
}

export default IconButton;