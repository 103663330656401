import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

function Box(props: {
    className?: string;
    children?: ReactNode;
}) 
{
    const classes = twMerge(
        "p-4 w-full bg-white/5 rounded-lg shadow-md border border-white/10",
        props.className
    ); 

    return (
        <div className={classes}>
            { props.children }
        </div>
    )
}

export default Box;